

















import { Component, Vue } from "vue-property-decorator";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin] })
export default class NotFound extends Vue {}
